import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'

import ShowPathModal from 'containers/AccessActivity/ShowPathModal'

import DyanmicFormattedMessage from 'components/DynamicFormattedMessage'
import { NodeViewDetailLabel } from 'components/NodeView'
import { TextLineShimmer } from 'components/ShimmerLoader'
import TextLink from 'components/TextLink'

import { metdataNestedObject } from 'utils/sonraiUtils'

import messages from './messages'

const CLASSIFICATION = 'Service Selected'

function K8sServiceDetail({ service, onServiceSelect }) {
  if (!service.metadata) {
    // eslint-disable-next-line no-console
    console.warn(
      'K8sServiceDetail: some service didnt have metadata:',
      service.toJS()
    )
    return null
  }
  const metadata = metdataNestedObject(service.metadata)
  const ports = _.get(metadata, ['service', 'spec', 'ports'], [])

  // try a coupla different techniques to get the service's name
  let name = service.name
  if (!name) {
    name = _.get(metadata, ['service', 'metadata', 'name'])
  }
  return (
    <div>
      <TextLink color="primary" onClick={() => onServiceSelect(service)}>
        {name}
      </TextLink>
      {' ('}
      {ports.map((port, i) => {
        return (
          <span key={i}>
            {port.protocol} {port.port}:{port.targetPort}
            {i !== ports.length - 1 ? ', ' : ''}
          </span>
        )
      })}
      {')'}
    </div>
  )
}
K8sServiceDetail.propTypes = {
  onServiceSelect: PropTypes.func.isRequired,
  service: ImmutablePropTypes.contains({
    name: PropTypes.string,
    metadata: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

function K8SServiceDetailsBody(props) {
  // cannot render anything w/ invalid props
  if (!props.podTemplate) {
    // eslint-disable-next-line no-console
    console.warn('K8sServiceDetails: expected to receive prop podTemplate')
    return null
  }

  // render loading state
  if (props.podTemplate.get('loading')) {
    return <TextLineShimmer />
  }

  // render error loading state
  if (props.podTemplate.get('error')) {
    return <DyanmicFormattedMessage {...messages.errrorLoadingData} />
  }

  const path = ['data', 'isSelectedByNetworkGroup', 'items']
  const services = props.podTemplate.getIn(path, null)

  if (services === null) {
    // eslint-disable-next-line no-console
    console.warn('K8sServiceDetails: podTemplate no data @ ' + path.join('/'))
    return <DyanmicFormattedMessage {...messages.errrorLoadingData} />
  }

  return services.map((service, i) => (
    <K8sServiceDetail
      key={i}
      service={service.toJS()}
      onServiceSelect={props.onServiceSelect}
    />
  ))
}

export default function K8sServiceDetails(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [paths, setPaths] = useState([])
  const [selectedService, setSelectedService] = useState({})
  const onServiceSelect = service => {
    setSelectedService(service)
    setModalOpen(true)
    setPaths(fromJS([[props.nodeData.srn]]))
  }
  return (
    <Fragment>
      <br />
      <NodeViewDetailLabel>
        <DyanmicFormattedMessage {...messages.selectedByServices} />
      </NodeViewDetailLabel>
      <K8SServiceDetailsBody {...{ ...props, onServiceSelect }} />
      <ShowPathModal
        resourceId={selectedService.resourceId}
        pathEndItem={selectedService}
        classification={CLASSIFICATION}
        paths={paths}
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
      />
    </Fragment>
  )
}

K8sServiceDetails.propTypes = {
  nodeData: PropTypes.object,
  podTemplate: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.error,
    data: ImmutablePropTypes.map,
  }),
}
