import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { DAEMON } from 'utils/constants'
import reducer from './reducers'
import sagas from './sagas'

import ToolTip from 'react-tooltip'
import NodeViewHeader from 'containers/NodeViewHeader'
import NodeView from 'containers/NodeSolutionCenter/NodeView'

import BorderedCard from 'components/BorderedCard'
import CopyToClipboard from 'components/CopyToClipboard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import JsonView from 'components/JsonView'
import Icon from 'components/Icon'
import SearchInput from 'components/SearchInput'
import TextLink from 'components/TextLink'
import YamlView from 'components/YamlView'

import K8sNodePolicyDetails from './K8sNodePolicyDetails'
import K8sPVDetails from './K8sPVDetails'
import K8sServiceDetails from './K8sServiceDetails'
import K8sWorkloadAffinity from './K8sWorkloadAffinity'
import K8sWorkloadContainers from './K8sWorkloadContainers'
import K8sWorkloadSpec from './K8sWorkloadSpec'
import K8sWorkloadTemplateSpec from './K8sWorkloadTemplateSpec'

import { metdataNestedObject } from 'utils/sonraiUtils'

import { getClusterInfrastructure, getPodTemplateData } from './actions'
import { selectClusterInfrastructure, selectPodTemplate } from './selectors'

import messages from './messages'
import K8sWorkloadInfrastructureTab from './K8sWorkloadInfrastructureTab'

const DISP_HUMAN = 'DISP_HUMAN'
const DISP_YAML = 'DISP_YAML'
const DISP_JSON = 'DISP_JSON'

export class K8sWorkloadNodeViewCardLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayMode: DISP_HUMAN,
      searchText: '',
      stringifiedText: '',
      searchMatches: 0, // how many things in the text view match the search
      currentSeachMatch: 0, // which search user is currenly scrolled to
    }

    this.textCardRef = React.createRef()

    this.styles = {
      textCopyButtonContainer: {
        position: 'absolute',
        top: '4px',
        right: '8px',
        cursor: 'pointer',
        zIndex: 1,
      },
    }
  }

  componentDidMount() {
    this.props.getClusterInfrastructure({
      srn: this.props.nodeId,
    })
    this.props.getPodTemplateData({
      srn: this.props.nodeId,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // if the user does a new search - set the state to keep track of the
    // matches and scroll to the first match
    if (this.state.searchText != prevState.searchText) {
      const textDiv = this.textCardRef.current
      const matches = textDiv.getElementsByClassName('filter-match') || []
      this.setState({ searchMatches: matches.length })

      const firstMatch = matches[0]
      if (firstMatch != undefined) {
        this.setState({ currentSeachMatch: 1 })
        textDiv.scrollTop = firstMatch.offsetTop - firstMatch.offsetHeight
      } else {
        textDiv.scrollTop = 0 // there was no matches so scroll to top
        this.setState({ currentSeachMatch: 0 })
      }
    }

    const choseNewMatch =
      this.state.currentSeachMatch != prevState.currentSeachMatch
    const switchedTextView =
      this.state.displayMode != prevState.displayMode &&
      [DISP_JSON, DISP_YAML].includes(this.state.displayMode)

    // if the user has tried to navigate to a new search result, scroll to it
    if (choseNewMatch || switchedTextView) {
      const textDiv = this.textCardRef.current
      if (this.state.currentSeachMatch !== 0) {
        const matches = textDiv.getElementsByClassName('filter-match') || []
        const scrollTo = matches[this.state.currentSeachMatch - 1]
        if (!scrollTo) {
          // eslint-disable-next-line no-console
          console.warn(
            'could not find filter highlght at index ' +
              this.state.currentSeachMatch
          )
        } else {
          textDiv.scrollTop = scrollTo.offsetTop - scrollTo.offsetHeight
        }
      }
    }
  }

  setSearchText = event => {
    this.setState({ searchText: event.target.value })
  }

  scrollToNextMatch = () => {
    if (this.state.currentSeachMatch < this.state.searchMatches) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState({ currentSeachMatch: this.state.currentSeachMatch + 1 })
    }
  }

  scrollToPrevMatch = () => {
    if (this.state.currentSeachMatch > 1) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.setState({ currentSeachMatch: this.state.currentSeachMatch - 1 })
    }
  }

  /**
   * The user can use the arrow keys to seach around in the document
   */
  handleSearchKeyDown = event => {
    let handled = false

    if (13 /* enter */ === event.keyCode) {
      handled = true
      if (event.shiftKey) {
        // shift + enter = go back
        this.scrollToPrevMatch()
      } else {
        // enter = go forward
        this.scrollToNextMatch()
      }
    }

    if (38 /* arrow up */ === event.keyCode) {
      handled = true
      this.scrollToPrevMatch()
    }

    if (40 /* arrow down */ === event.keyCode) {
      handled = true
      this.scrollToNextMatch()
    }

    if (handled) {
      event.preventDefault()
    }
  }

  onStringify = stringifiedText => {
    if (stringifiedText != this.stringifiedText) {
      this.setState({ stringifiedText })
    }
  }

  /**
   * There are different displays - like a normal node views (cards) like a
   * humarn would read, or a Yaml or JSON node view like a computer or a
   * developer would read.
   */
  getGridContent = () => {
    switch (this.state.displayMode) {
      case DISP_HUMAN: {
        const gridContent = [
          <K8sWorkloadSpec key="spec" nodeData={this.props.nodeData} />,
          <K8sWorkloadTemplateSpec
            key="templateSpec"
            nodeData={this.props.nodeData}
          />,
          <K8sWorkloadContainers
            key="containers"
            nodeData={this.props.nodeData}
          />,
        ]
        if (this.hasAffinity()) {
          gridContent.push(
            <K8sWorkloadAffinity
              key="affinity"
              nodeData={this.props.nodeData}
            />
          )
        }

        return gridContent
      }
      case DISP_YAML:
        return [
          <div key="yaml">
            <div style={this.styles.textCopyButtonContainer}>
              <CopyToClipboard value={this.state.stringifiedText} />
            </div>
            <BorderedCard style={{ height: '100%' }}>
              <div
                ref={this.textCardRef}
                style={{ height: '100%', overflowY: 'scroll' }}
              >
                <YamlView
                  highlightText={this.state.searchText}
                  onStringify={this.onStringify}
                  value={metdataNestedObject(this.props.nodeData.metadata)}
                />
              </div>
            </BorderedCard>
          </div>,
        ]
      case DISP_JSON:
        return [
          <div key="yaml">
            <div style={this.styles.textCopyButtonContainer}>
              <CopyToClipboard value={this.state.stringifiedText} />
            </div>
            <BorderedCard style={{ height: '100%' }}>
              <div
                ref={this.textCardRef}
                style={{ height: '100%', overflowY: 'scroll' }}
              >
                <JsonView
                  highlightText={this.state.searchText}
                  onStringify={this.onStringify}
                  value={metdataNestedObject(this.props.nodeData.metadata)}
                />
              </div>
            </BorderedCard>
          </div>,
        ]
    }
  }

  getLayouts = () => {
    const controlHeight = this.state.displayMode === DISP_HUMAN ? 0.15 : 0.25
    const layouts = {
      controls: {
        lg: { w: 2, h: controlHeight },
        xs: { w: 2, h: controlHeight },
      },
      yaml: {
        lg: { w: 2, h: 5 },
        xs: { w: 2, h: 5 },
      },
      spec: {
        lg: { w: 1, h: 1.5 },
        xs: { w: 1, h: 1.5 },
      },
      templateSpec: {
        lg: { w: 1, h: 1.5 },
        xs: { w: 1, h: 1.5 },
      },
      containers: {
        lg: { w: 2, h: 3.5 },
        xs: { w: 2, h: 3.5 },
      },
    }

    if (this.hasAffinity()) {
      layouts.affinity = {
        lg: { w: 1, h: 1.5 },
        xs: { w: 1, h: 1.5 },
      }
    }

    return layouts
  }

  getCols = () => {
    return {
      lg: 2,
      xs: 2,
    }
  }

  getTabs = () => {
    return [
      <div key="pod-template-tab" label="Infrastructure">
        <K8sWorkloadInfrastructureTab
          nodeData={this.props.nodeData}
          clusterInfrastructure={this.props.clusterInfrastructure}
        />
      </div>,
    ]
  }

  hasAffinity = () => {
    if (this.props.nodeData && this.props.nodeData.metadata) {
      const metadata = metdataNestedObject(this.props.nodeData.metadata)
      const affinity = _.get(
        metadata,
        [Object.keys(metadata)[0], 'spec', 'template', 'spec', 'affinity'],
        null
      )
      if (affinity != null) {
        return true
      }
    }
    return false
  }

  setViewHuman = () => {
    this.setState({ displayMode: DISP_HUMAN })
  }

  setViewJson = () => {
    this.setState({ displayMode: DISP_JSON })
  }

  setViewYAML = () => {
    this.setState({ displayMode: DISP_YAML })
  }

  renderViewControl = () => {
    return (
      <div
        key="controls"
        style={{
          width: '100%',
          textAlign: 'right',
        }}
      >
        {this.state.displayMode != DISP_HUMAN && (
          <div
            style={{
              position: 'absolute',
              left: 8,
              width: '350px',
              textAlign: 'left',
            }}
          >
            <div
              style={{ display: 'inline-block', width: 'calc(100% - 100px)' }}
            >
              <SearchInput
                onChange={this.setSearchText}
                onKeyDown={this.handleSearchKeyDown}
              />
            </div>
            {this.state.searchText != '' && (
              <div
                style={{
                  display: 'inline-block',
                  width: '100px',
                }}
              >
                <span
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={this.scrollToPrevMatch}
                >
                  <Icon fa name="caret-up" />
                </span>
                <span
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={this.scrollToNextMatch}
                >
                  <Icon fa name="caret-down" />
                </span>
                <span style={{ marginLeft: '8px', userSelect: 'none' }}>
                  {this.state.currentSeachMatch} of {this.state.searchMatches}
                </span>
              </div>
            )}
          </div>
        )}
        <ToolTip id="k8s-nodeview-human" place="bottom" effect="solid">
          <DynamicFormattedMessage {...messages.viewControlHuman} />
        </ToolTip>
        <TextLink
          data-tip
          data-for="k8s-nodeview-human"
          style={{ paddingRight: '12px' }}
          onClick={this.setViewHuman}
          color="primary"
        >
          <Icon fa name="browser" />
        </TextLink>

        <ToolTip id="k8s-nodeview-yaml" place="bottom" effect="solid">
          <DynamicFormattedMessage {...messages.viewControlYAML} />
        </ToolTip>
        <TextLink
          data-tip
          data-for="k8s-nodeview-yaml"
          color="primary"
          style={{ paddingRight: '12px' }}
          onClick={this.setViewYAML}
        >
          <span
            style={{
              position: 'relative',
              left: '10.5px',
              fontSize: '10px',
            }}
          >
            Y
          </span>
          <Icon fa name="file" />
        </TextLink>
        <ToolTip id="k8s-nodeview-json" place="bottom" effect="solid">
          <DynamicFormattedMessage {...messages.viewControlJSON} />
        </ToolTip>
        <TextLink
          data-tip
          data-for="k8s-nodeview-json"
          color="primary"
          style={{ paddingRight: '12px' }}
          onClick={this.setViewJson}
        >
          <Icon fa name="brackets-curly" />
        </TextLink>
      </div>
    )
  }

  getHeaderContent = () => {
    return [
      <K8sServiceDetails
        key="service-details"
        nodeData={this.props.nodeData}
        podTemplate={this.props.podTemplate}
      />,
      <K8sNodePolicyDetails
        key="network-policy-details"
        nodeData={this.props.nodeData}
        podTemplate={this.props.podTemplate}
      />,
      <K8sPVDetails
        key="network-policy-details"
        nodeData={this.props.nodeData}
        podTemplate={this.props.podTemplate}
      />,
    ]
  }

  render() {
    return (
      <Fragment>
        <NodeViewHeader
          nodeId={this.props.nodeId}
          onNodeView
          horizontal={this.props.horizontal}
        />
        <div className="k8s-workload-nodeviewcardlayout">
          <NodeView
            {...this.props}
            additionalHeaderDetails={this.getHeaderContent()}
            horizontal={this.props.horizontal}
            renderDetailsTab
            layouts={this.getLayouts()}
            cols={this.getCols()}
            gridContent={[this.renderViewControl(), ...this.getGridContent()]}
            tabs={this.getTabs()}
          />
        </div>
      </Fragment>
    )
  }
}

K8sWorkloadNodeViewCardLayout.propTypes = {
  // ~~ passed props ~~
  horizontal: PropTypes.bool,
  nodeData: PropTypes.object.isRequired,
  nodeId: PropTypes.string.isRequired,

  // ~~ bound action creators ~~
  getClusterInfrastructure: PropTypes.func.isRequired,
  getPodTemplateData: PropTypes.func.isRequired,

  // ~~ props from redux ~~
  clusterInfrastructure: ImmutablePropTypes.map,
  podTemplate: ImmutablePropTypes.map,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClusterInfrastructure,
      getPodTemplateData,
    },
    dispatch
  )
}

const mapStateToProps = createStructuredSelector({
  clusterInfrastructure: selectClusterInfrastructure,
  podTemplate: selectPodTemplate,
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'k8sWorkloadNodeView', reducer })
const withSaga = injectSaga({
  key: 'k8sWorkloadNodeView',
  saga: sagas,
  DAEMON,
})

export default compose(
  withConnect,
  withReducer,
  withSaga
)(K8sWorkloadNodeViewCardLayout)
