import _ from 'lodash'

export const getNiceLabel = camel => {
  return _.split(_.snakeCase(camel), '_')
    .map(_.capitalize)
    .join(' ')
}

/**
 * Convert the actual byte number like 41984019480194 into
 * something meaningful like "4 GB"
 */
const denominations = ['bytes', 'KB', 'MB', 'GB', 'TB']
export const convertToNiceBytes = number => {
  let denomIndex = 0
  while (number > 1024 && denomIndex < denominations.length) {
    number = Math.floor(number / 1024)

    denomIndex++
  }
  return `${number} ${denominations[denomIndex]}`
}


/**
 * Resource limits and requests have a weird format so this converts it
 * into somehting that would make sense to the user
 */
export const convertResourceAmountToNiceDisplay = ({ format, number }) => {
  switch (format) {
    case 'DECIMAL_SI':
      return number
    case 'BINARY_SI':
      return convertToNiceBytes(number)
    default:
      return JSON.stringify({ format, number })
  }
}
