import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import yammyBoi from './yammyModule.js'
import _ from 'lodash'

import CodeMirror from 'codemirror'
import 'codemirror/addon/runmode/runmode'
import 'codemirror/mode/yaml/yaml'

const styles = {
  displayStyles: {
    filterMatch: {
      fontWeight: 'bold',
      backgroundColor: '#ffc1075e',
    },
    comment: {
      color: 'green',
    },
    property: {
      color: 'black',
    },
    string: {
      color: '#007ad9',
    },
  },
}

export const YamlView = props => {
  const [stringifiedText, setStringifiedText] = useState('')

  useEffect(() => {
    if (stringifiedText) {
      props.onStringify(stringifiedText)
    }
  }, [stringifiedText])

  let ymlText
  if (props.skipParsing) {
    ymlText = props.value
  } else {
    let obj
    if (_.isObject(props.value)) {
      obj = props.value
    } else {
      try {
        obj = JSON.parse(props.value)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('could not parse props.value as JSON: ' + props.value, e)
      }
    }

    ymlText = yammyBoi.stringify(obj)
  }
  if (stringifiedText != ymlText) {
    setStringifiedText(ymlText)
  }

  const tokens = []
  CodeMirror.runMode(
    ymlText,
    {
      name: 'text/yaml',
    },
    (token, style) => {
      const tokenStyles = (style || '')
        .split(' ')
        .map(className => {
          return styles.displayStyles[className.trim()]
        })
        .filter(style => style)
        .reduce((allStyles, style) => {
          return {
            ...allStyles,
            ...style,
          }
        }, {})

      // if the text matches the search text, add an additional class
      if (props.highlightText) {
        let highlightText = props.highlightText.toLowerCase()
        if (
          token &&
          highlightText !== '' &&
          token.toLowerCase().includes(highlightText)
        ) {
          style += ' filter-match'
          Object.assign(tokenStyles, styles.displayStyles.filterMatch)
        }
      }
      tokens.push(
        <span className={style} style={tokenStyles} key={tokens.length}>
          {token}
        </span>
      )
    }
  )

  return (
    <div>
      <pre>{tokens}</pre>
    </div>
  )
}

YamlView.defaultProps = {
  skipParsing: false,
  onStringify: () => {},
}

YamlView.propTypes = {
  highlightText: PropTypes.string,
  skipParsing: PropTypes.bool,
  onStringify: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object).isRequired,
}

export default YamlView
