import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'

import { metdataNestedObject } from 'utils/sonraiUtils'

import messages from './messages'
import { getNiceLabel } from './utils'
import './styles.css'

const specialValueRenderers = {
  // eslint-disable-next-line react/display-name
  securityContext: value => {
    const textBits = []
    Object.keys(value).forEach(key => {
      textBits.push(`${key}: ${value[key]}`)
    })

    return <div>{textBits.join(',\u00A0\u00A0')}</div>
  },

  selector: value => {
    return Object.keys(value.matchLabels).map(key => {
      return `${key}:${value.matchLabels[key]}`
    })
  },

  strategy: value => {
    const strat = Object.values(value)[0]
    let segments = [
      `${getNiceLabel(value.type)}`,
      ...Object.keys(strat).map(key => `${getNiceLabel(key)}: ${strat[key]}`),
    ]
    return segments.join(',\u00A0\u00A0')
  },

  // eslint-disable-next-line react/display-name
  volumes: value => {
    const lis = value.map((volume, i) => {
      const { name, configMap } = volume

      let configMapData = []
      if (configMap) {
        Object.keys(configMap).forEach(key => {
          configMapData.push(`${key}: ${configMap[key]}`)
        })
      }
      return (
        <li key={i}>
          {name} {configMapData.length > 0 && ' - '}
          {configMapData.join(', ')}
        </li>
      )
    })

    return <ul style={{ listStyleType: 'disc' }}>{lis}</ul>
  },
}

export class K8sWorkloadTemplateSpec extends Component {
  constructor(props) {
    super(props)
    this.state = { invalidMetadata: true }
  }

  getData = () => {
    const metadata = metdataNestedObject(this.props.nodeData.metadata)
    const data = _.get(metadata, [
      Object.keys(metadata)[0],
      'spec',
      'template',
      'spec',
    ])
    if (null == data) {
      this.setState({ invalidMetadata: true })
      return null
    }

    return _.omit(data, [
      'metadata',
      'containers',
      'initContainers',
      'affinity',
    ])
  }

  renderDataSections = () => {
    const data = this.getData()
    if (null === data) {
      return null
    }

    const sections = Object.keys(data)
      .sort()
      .map((key, i) => {
        let valueToRender
        if (specialValueRenderers[key]) {
          valueToRender = specialValueRenderers[key](data[key])
        } else {
          valueToRender = _.isObject(data[key])
            ? JSON.stringify(data[key])
            : data[key]
        }
        return (
          <Fragment key={i}>
            <div className="k8s-spec-section-label">{getNiceLabel(key)}</div>
            <div className="k8s-spec-section-value">{valueToRender}</div>
          </Fragment>
        )
      })

    return sections
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%', overflowY: 'scroll' }}>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.templateSpecSectionHeader} />
        </SectionHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(180px, auto) auto',
          }}
        >
          {this.renderDataSections()}
        </div>
      </BorderedCard>
    )
  }
}

K8sWorkloadTemplateSpec.propTypes = {
  nodeData: PropTypes.shape({
    metadata: PropTypes.string.isRequired,
  }).isRequired,
}

export default K8sWorkloadTemplateSpec
