import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectK8sWorkloadNodeview = state =>
  state.get('k8sWorkloadNodeView') || Map()

export const selectPodTemplate = createSelector(
  selectK8sWorkloadNodeview,
  state => {
    return state.get('podTemplate') || Map()
  }
)

export const selectClusterInfrastructure = createSelector(
  selectK8sWorkloadNodeview,
  state => {
    return state.get('clusterInfrastructure') || Map()
  }
)
