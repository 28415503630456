import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'

import messages from './messages'

import { metdataNestedObject } from 'utils/sonraiUtils'
import { getNiceLabel } from './utils'

export class K8sWorkloadAffinity extends Component {
  getAffinityConfig = () => {
    const metadata = metdataNestedObject(this.props.nodeData.metadata)
    return _.get(metadata, [
      Object.keys(metadata)[0],
      'spec',
      'template',
      'spec',
      'affinity',
    ])
  }

  getAffinities = affinityConfig => {
    const affinities = []
    if (affinityConfig.nodeAffinity) {
      affinities.push({
        type: 'node',
        ...affinityConfig.nodeAffinity,
      })
    }
    if (affinityConfig.podAffinity) {
      affinities.push({
        type: 'pod',
        ...affinityConfig.podAffinity,
      })
    }
    return affinities
  }

  getAntiAffinities = affinityConfig => {
    const affinities = []
    if (affinityConfig.nodeAntiAffinity) {
      affinities.push({
        type: 'node',
        ...affinityConfig.nodeAntiAffinity,
      })
    }
    if (affinityConfig.podAntiAffinity) {
      affinities.push({
        type: 'pod',
        ...affinityConfig.podAntiAffinity,
      })
    }
    return affinities
  }

  getOtherData = value => {
    const dataSections = []
    const topologyKey = _.get(value, ['topologyKey'], null)
    if (topologyKey != null) {
      dataSections.push(
        <Fragment>
          <div className="probe-section-label">Topology Key:</div>
          <div>{topologyKey}</div>
        </Fragment>
      )
    }

    const matchLabels = _.get(value, ['labelSelector', 'matchLabels'], null)
    if (matchLabels != null) {
      const matchLabelsText = Object.keys(matchLabels).map(key => {
        return `${key}:${matchLabels[key]}`
      })
      dataSections.push(
        <Fragment>
          <div className="probe-section-label">Match Labels:</div>
          <div>{matchLabelsText.join(',')}</div>
        </Fragment>
      )
    }

    return dataSections
  }

  renderAffinityInfo = ({ anti = false, type, key, value }) => {
    const dataSections = []
    if (value.podAffinityTerm) {
      this.getOtherData(value.podAffinityTerm).forEach(d =>
        dataSections.push(d)
      )
    } else {
      this.getOtherData(value).forEach(d => dataSections.push(d))
    }

    let title = [_.capitalize(type), anti ? 'Anti' : '', 'Affinity'].join(' ')
    const description = getNiceLabel(key)
    return (
      <Fragment>
        <div
          className="k8s-spec-section-label"
          style={{ gridColumn: 'span 2' }}
        >
          {title} - {description}
        </div>
        {dataSections}
        <div style={{ gridColumn: 'span 2', marginBottom: '8px' }} />
      </Fragment>
    )
  }

  render() {
    const affinityConfig = this.getAffinityConfig()
    const affinities = this.getAffinities(affinityConfig)
    const antiAffinities = this.getAntiAffinities(affinityConfig)

    const affinityComponents = affinities.flatMap(affinity => {
      const { type } = affinity
      return Object.keys(_.omit(affinity, ['type'])).map(key => {
        const value = affinity[key]
        return this.renderAffinityInfo({ type, key, value })
      })
    })

    const antiAffinityComponents = antiAffinities.flatMap(affinity => {
      const { type } = affinity
      return Object.keys(_.omit(affinity, ['type'])).flatMap(key => {
        const values = affinity[key]
        return [...values].map(value =>
          this.renderAffinityInfo({ anti: true, type, key, value })
        )
      })
    })

    return (
      <BorderedCard style={{ height: '100%', overflowY: 'scroll' }}>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.affinitySectionHeader} />
        </SectionHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
          }}
        >
          {affinityComponents}
          {antiAffinityComponents}
        </div>
      </BorderedCard>
    )
  }
}

K8sWorkloadAffinity.propTypes = {
  nodeData: PropTypes.shape({
    metadata: PropTypes.string.isRequired,
  }).isRequired,
}

export default K8sWorkloadAffinity
