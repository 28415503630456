import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from 'react-select'

import BorderedCard from 'components/BorderedCard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'

import { metdataNestedObject } from 'utils/sonraiUtils'

import messages from './messages'
import { convertResourceAmountToNiceDisplay, getNiceLabel } from './utils'

// eslint-disable-next-line react/display-name
const probeDisplay = name => value => {
  const { httpGet } = value
  let endpoint = null
  if (httpGet) {
    endpoint = `${httpGet.scheme} GET ${httpGet.port} ${httpGet.path}`
  }
  return (
    <Fragment>
      <div
        className="k8s-spec-section-label"
        style={{
          marginTop: '12px',
          marginBottom: '8px',
          gridColumn: 'span 2',
        }}
      >
        {getNiceLabel(name)}:
      </div>
      {endpoint && (
        <Fragment>
          <div className="probe-section-label">Endpoint</div>
          <div>{endpoint}</div>
        </Fragment>
      )}
      <div className="probe-section-label">
        {getNiceLabel('failureThreshold')}
      </div>
      <div>{value.failureThreshold}</div>
      <div className="probe-section-label">
        {getNiceLabel('successThreshold')}
      </div>
      <div>{value.successThreshold}</div>
      <div className="probe-section-label">
        {getNiceLabel('initialDelaySeconds')}
      </div>
      <div>{value.initialDelaySeconds}s</div>
      <div className="probe-section-label">{getNiceLabel('periodSeconds')}</div>
      <div>{value.periodSeconds}s</div>

      <div className="probe-section-label">
        {getNiceLabel('timeoutSeconds')}
      </div>
      <div>{value.timeoutSeconds}s</div>
    </Fragment>
  )
}

const sectionsOfResourceLimits = limits => {
  const sections = []
  if (limits.cpu) {
    sections.push(<div className="probe-section-label">CPU</div>)
    sections.push(<div>{convertResourceAmountToNiceDisplay(limits.cpu)}</div>)
  }

  if (limits.memory) {
    sections.push(<div className="probe-section-label">Memory</div>)
    sections.push(
      <div>{convertResourceAmountToNiceDisplay(limits.memory)}</div>
    )
  }
  return sections
}

const keyValuesDetailsOmit = [
  'livenessProbe',
  'readinessProbe',
  'resources',
  'ports',
  'volumeMounts',
]

const specialValueRenderers = {
  args: value => value.join(' '),
  command: value => value.join(' '),
  livenessProbe: probeDisplay('livenessProbe'),
  readinessProbe: probeDisplay('readinessProbe'),
  ports: values => {
    const sections = []
    sections.push(
      <div
        className="k8s-spec-section-label"
        style={{
          marginTop: '12px',
          marginBottom: '8px',
          gridColumn: 'span 2',
        }}
      >
        Ports
      </div>
    )

    values
      .flatMap(port => {
        const innerSections = []
        Object.keys(port).map((key, i) => {
          const labelClass =
            i === 0 ? 'probe-section-label' : 'probe-section-label-nodash'
          innerSections.push(
            <div className={labelClass}>{getNiceLabel(key)}</div>
          )
          innerSections.push(
            <div className="probe-section-value">{port[key]}</div>
          )
        })
        return innerSections
      })
      .forEach(section => sections.push(section))
    return sections
  },
  resources: value => {
    const sections = []
    if (value.limits) {
      sections.push(
        <div
          className="k8s-spec-section-label"
          style={{
            marginTop: '12px',
            marginBottom: '8px',
            gridColumn: 'span 2',
          }}
        >
          Resource Limits
        </div>
      )
      sectionsOfResourceLimits(value.limits).forEach(section =>
        sections.push(section)
      )
    }

    if (value.requests) {
      sections.push(
        <div
          className="k8s-spec-section-label"
          style={{
            marginTop: '12px',
            marginBottom: '8px',
            gridColumn: 'span 2',
          }}
        >
          Resource Requests
        </div>
      )
      sectionsOfResourceLimits(value.requests).forEach(section =>
        sections.push(section)
      )
    }

    return sections
  },
  volumeMounts: values => {
    const sections = []
    sections.push(
      <div
        className="k8s-spec-section-label"
        style={{
          marginTop: '12px',
          marginBottom: '8px',
          gridColumn: 'span 2',
        }}
      >
        Volume Mounts
      </div>
    )

    values.forEach(value => {
      sections.push(<div className={'probe-section-label'}>Name</div>)
      sections.push(<div className="probe-section-value">{value.name}</div>)
      Object.keys(_.omit(value, ['name'])).forEach(key => {
        sections.push(
          <div className={'probe-section-label-nodash'}>
            {getNiceLabel(key)}
          </div>
        )
        sections.push(<div className="probe-section-value">{value[key]}</div>)
      })
    })
    return sections
  },
}

export class K8sWorkloadContainers extends React.Component {
  constructor(props) {
    super(props)

    const containers = this.getContainers()

    this.state = {
      invalidMetadata: false,
      selectedContainer: containers[0],
    }
  }

  getContainers = () => {
    const metadata = metdataNestedObject(this.props.nodeData.metadata)
    const containers = _.get(metadata, [
      Object.keys(metadata)[0],
      'spec',
      'template',
      'spec',
      'containers',
    ])
    if (null == containers) {
      this.setState({ invalidMetadata: true })
      return null
    }

    const initContainers = _.get(
      metadata,
      [Object.keys(metadata)[0], 'spec', 'template', 'spec', 'initContainers'],
      []
    ).map(c => ({ initContainer: 'true', ...c }))
    return [...containers, ...initContainers]
  }

  renderContainerSelect = containers => {
    return (
      <Select
        onChange={value => {
          const cont = containers.find(c => c.name === value.value)
          if (cont) {
            this.setState({ selectedContainer: cont })
          }
        }}
        value={{
          value: this.state.selectedContainer.name,
          label:
            this.state.selectedContainer.name +
            (this.state.selectedContainer.initContainer === 'true'
              ? ' (Init Container)'
              : ''),
        }}
        options={containers.map(({ name, initContainer }) => {
          return {
            value: name,
            label: name + (initContainer === 'true' ? ' (Init Container)' : ''),
          }
        })}
      />
    )
  }

  renderContainerData = () => {
    const data = _.omit(this.state.selectedContainer, [
      'env',
      ...keyValuesDetailsOmit,
    ])
    const segments = Object.keys(data).map((key, i) => {
      let valueToRender
      if (specialValueRenderers[key]) {
        valueToRender = specialValueRenderers[key](data[key])
      } else {
        valueToRender = _.isObject(data[key])
          ? JSON.stringify(data[key])
          : data[key]
      }
      return (
        <Fragment key={i}>
          <div className="k8s-spec-section-label">{getNiceLabel(key)}</div>
          <div className="k8s-spec-section-value">{valueToRender}</div>
        </Fragment>
      )
    })
    return segments
  }

  renderContainerOtherData = () => {
    const data = _.pick(this.state.selectedContainer, keyValuesDetailsOmit)
    const segments = Object.keys(data).map(key => {
      if (specialValueRenderers[key]) {
        return specialValueRenderers[key](data[key])
      }
    })
    return segments
  }

  renderContainerEnvironment = () => {
    const env = this.state.selectedContainer.env || []
    const segments = env.map(({ name, value }) => {
      return (
        <Fragment key={name}>
          <div className="k8s-env-section-label">
            <span>{name}</span>
          </div>
          <div className="k8s-env-section-value">
            <span>{value}</span>
          </div>
        </Fragment>
      )
    })
    return segments
  }

  render() {
    const containers = this.getContainers()

    return (
      <BorderedCard style={{ height: '100%' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto 200px' }}>
          <SectionHeader>
            <DynamicFormattedMessage {...messages.containersSectionHeader} />
          </SectionHeader>
          <div>{this.renderContainerSelect(containers)}</div>
        </div>
        <div
          style={{
            height: 'calc(100% - 50px)',
            overflowY: 'scroll',
            paddingTop: '18px',
            marginTop: '6px',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              width: '50%',
              float: 'left',
              paddingRight: '16px',
            }}
          >
            {this.renderContainerData()}
            {this.renderContainerOtherData()}
          </div>
          <div
            style={{
              width: '50%',
              float: 'right',
              borderLeft: '1px solid #eee',
              paddingLeft: '16px',
              position: 'relative',
              left: '-8px',
            }}
          >
            <div
              style={{
                fontSize: '14px',
                fontWeight: 400,
                marginBottom: '8px',
              }}
            >
              Environment:
            </div>
            <div
              className="k8s-env-section"
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
              }}
            >
              {this.renderContainerEnvironment()}
            </div>
          </div>
        </div>
      </BorderedCard>
    )
  }
}

K8sWorkloadContainers.propTypes = {
  nodeData: PropTypes.shape({
    metadata: PropTypes.string.isRequired,
  }).isRequired,
}

export default K8sWorkloadContainers
