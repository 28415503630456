import gql from 'graphql-tag'

export const GET_CLUSTER_INFRA = gql`
  query getClusterInfra_part1($srn: String) {
    Workloads(where: { srn: { value: $srn } }) {
      items {
        isIn(
          where: {
            label: { value: "ResourceGroup" }
            on__Resourcegroup: { type: { value: K8SNamespace } }
          }
        ) {
          items {
            srn
            isIn(
              where: {
                label: { value: "Account" }
                on__Account: { type: { value: K8SCluster } }
              }
            ) {
              items {
                srn
                name
                isIn(
                  where: {
                    label: { value: "Compute" }
                    on__Compute: { type: { value: AzureKubernetesCluster } }
                  }
                ) {
                  items {
                    name
                    contains {
                      items {
                        srn
                        label
                        active
                        createdDate
                        ... on Resource {
                          name
                        }
                        ... on Network {
                          type
                        }
                        ... on Networksubnet {
                          type
                        }
                        ... on Networkpolicy {
                          type
                        }
                        ... on Networkcomponent {
                          type
                        }
                        ... on Blockstorage {
                          type
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_POD_TEMPLATE_DATA_QUERY = gql`
  query getPodTemplateData($srn: String) {
    Workloads(where: { srn: { value: $srn } }) {
      items {
        srn
        runsOn {
          items {
            srn
            name
            label
            type
            metadata
            isSelectedByNetworkPolicy {
              items {
                srn
                label
                name
                type
                metadata
                protectsIngress {
                  items {
                    srn
                    label
                    type
                    metadata
                  }
                }
                protectsEgress {
                  items {
                    srn
                    label
                    type
                    metadata
                  }
                }
              }
            }
            isSelectedByNetworkGroup {
              items {
                label
                type
                auditEnabled
                dnsName
                type
                active
                account
                availabilityZone
                createdDate
                lastAlertDate
                content
                cloudType
                country
                createdBy
                importance
                friendlyName
                highestAlertSeverity
                resourceId
                sid
                name
                modifiedDate
                observedDate
                region
                serviceType
                loadId
                label
                srn
                tagCount
                timestamp
                metadata
                isRegisteredWith {
                  items {
                    srn
                    label
                    isIn {
                      items {
                        active
                        account
                        availabilityZone
                        createdDate
                        auditEnabled
                        lastAlertDate
                        content
                        cloudType
                        country
                        createdBy
                        importance
                        friendlyName
                        highestAlertSeverity
                        resourceId
                        sid
                        name
                        modifiedDate
                        observedDate
                        region
                        serviceType
                        loadId
                        label
                        srn
                        tagCount
                        timestamp
                      }
                    }
                  }
                }
              }
            }
            hasAttachedStorage {
              items {
                srn
                name
                label
                metadata
                createdBy
                ... on Datacontainer {
                  type
                }
                ... on Entity {
                  relations(
                    where: {
                      relation: { name: { value: "contains" } }
                      item: { type: { value: "K8SPersistentVolume" } }
                    }
                  ) {
                    relation {
                      name
                      direction
                    }
                    item {
                      srn
                      label
                      ... on Datacontainer {
                        type
                        metadata
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
