import { all, put, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  getClusterInfrastructureError,
  getClusterInfrastructureSucces,
  getPodTemplateDataError,
  getPodTemplateDataSuccess,
} from './actions'
import { GET_CLUSTER_INFRASTRUCTURE, GET_POD_TEMPLATE_DATA } from './constants'
import { GET_CLUSTER_INFRA, GET_POD_TEMPLATE_DATA_QUERY } from './queries'

function* getClusterInfrastructure(action) {
  try {
    const client = yield getClient()
    const { srn } = action.payload
    const result = yield client.query({
      query: GET_CLUSTER_INFRA,
      variables: { srn },
    })

    const path = [
      'data',
      'Workloads',
      'items',
      0,
      'isIn',
      'items',
      0,
      'isIn',
      'items',
      0,
      'isIn',
      'items',
      0,
      'contains',
      'items',
    ]

    const contents = _.get(result, path, null)
    if (null == contents) {
      throw new Error('Could not get cluster contents: ' + path.join('/'))
    }

    yield put(getClusterInfrastructureSucces({ data: contents }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching cluster infrastructure', e)
    yield put(getClusterInfrastructureError())
  }
}

function* getPodTemplateData(action) {
  try {
    const client = yield getClient()
    const { srn } = action.payload
    const result = yield client.query({
      query: GET_POD_TEMPLATE_DATA_QUERY,
      variables: { srn },
    })

    const path = ['data', 'Workloads', 'items', 0, 'runsOn', 'items', 0]
    const podTemplate = _.get(result, path, null)
    if (podTemplate === null) {
      throw new Error('The response did not return data at: ' + path.join('/'))
    }

    yield put(getPodTemplateDataSuccess({ data: podTemplate }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching the pod template data: ', e)
    yield put(getPodTemplateDataError())
  }
}

function* k8sWorkloadNodeViewSaga() {
  yield all([
    takeLatest(GET_CLUSTER_INFRASTRUCTURE, getClusterInfrastructure),
    takeLatest(GET_POD_TEMPLATE_DATA, getPodTemplateData),
  ])
}

export default k8sWorkloadNodeViewSaga
