import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import BorderedCard from 'components/BorderedCard'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'

import { metdataNestedObject } from 'utils/sonraiUtils'

import messages from './messages'
import { getNiceLabel } from './utils'
import './styles.css'

const specialValueRenderers = {
  selector: value => {
    return Object.keys(value.matchLabels).map(key => {
      return `${key}:${value.matchLabels[key]}`
    })
  },

  strategy: value => {
    const strat = Object.values(value)[0]
    let segments = [
      `${getNiceLabel(value.type)}`,
      ...Object.keys(strat).map(key => `${getNiceLabel(key)}: ${strat[key]}`),
    ]
    return segments.join(',\u00A0\u00A0')
  },

  updateStrategy: value => {
    return value.type
  },
}

export class K8sWorkloadSpec extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invalidMetadata: false,
    }
  }

  /**
   * Get from the metadata the important shit that should be rendered
   * in this component
   */
  getSpecData = () => {
    const metadata = metdataNestedObject(this.props.nodeData.metadata)
    const spec = _.get(metadata, [Object.keys(metadata)[0], 'spec'], null)
    if (null === spec) {
      this.setState({ invalidMetadata: true })
      return null
    }
    return _.omit(spec, ['template'])
  }

  renderSpecDataSections = () => {
    const specData = this.getSpecData()
    if (null === specData) {
      return null
    }

    const sections = Object.keys(specData)
      .sort()
      .map((key, i) => {
        let valueToRender
        if (specialValueRenderers[key]) {
          valueToRender = specialValueRenderers[key](specData[key])
        } else {
          valueToRender = _.isObject(specData[key])
            ? JSON.stringify(specData[key])
            : specData[key]
        }
        return (
          <Fragment key={i}>
            <div className="k8s-spec-section-label">{getNiceLabel(key)}</div>
            <div className="k8s-spec-section-value">{valueToRender}</div>
          </Fragment>
        )
      })

    return sections
  }

  render() {
    return (
      <BorderedCard style={{ height: '100%' }}>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.specSectionHeader} />
        </SectionHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'minmax(180px, auto) auto',
          }}
        >
          {this.renderSpecDataSections()}
        </div>
      </BorderedCard>
    )
  }
}

K8sWorkloadSpec.propTypes = {
  nodeData: PropTypes.object.isRequired,
}

export default K8sWorkloadSpec
