import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { fromJS } from 'immutable'

import ShowPathModal from 'containers/AccessActivity/ShowPathModal'

import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import { NodeViewDetailLabel } from 'components/NodeView'
import { TextLineShimmer } from 'components/ShimmerLoader'
import TextLink from 'components/TextLink'

import { metdataNestedObject } from 'utils/sonraiUtils'
import messages from './messages'
import { convertResourceAmountToNiceDisplay } from './utils'

const CLASSIFICATION = 'Persistent Volume Claims'

/**
 * this the main line item for a PVC
 */
function K8sPVDetail({ pvc, onSelectedPVC }) {
  const { name } = pvc
  const metadata = metdataNestedObject(pvc.metadata)
  const storage = _.get(
    metadata,
    ['persistentVolumeClaim', 'spec', 'resources', 'requests', 'storage'],
    null
  )

  const volumeMode = _.get(
    metadata,
    ['persistentVolumeClaim', 'spec', 'volumeMode'],
    null
  )

  // only render some extra details in brackets if we can like (FileSystem 2GB)
  let detailsPart = []
  if (storage != null || volumeMode != null) {
    detailsPart.push(' (')

    // try to add type of volume
    if (volumeMode) {
      detailsPart.push(volumeMode)
      if (storage) {
        // if also storage, put a space
        detailsPart.push(' ')
      }
    }

    // put the storage part
    if (storage) {
      detailsPart.push(convertResourceAmountToNiceDisplay(storage))
    }

    detailsPart.push(')') // close bracket
  }
  return (
    <div>
      <TextLink color="primary" onClick={() => onSelectedPVC(pvc)}>
        {name}
      </TextLink>
      <span>{detailsPart.join('')}</span>
    </div>
  )
}

K8sPVDetail.propTypes = {
  onSelectedPVC: PropTypes.func.isRequired,
  pvc: PropTypes.shape({
    name: PropTypes.string,
    metadata: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
}

/**
 * this he body
 */
function K8sPVDetailsBody(props) {
  if (!props.podTemplate) {
    // eslint-disable-next-line no-console
    console.warn('K8sPVDetails: expected to receive prop podTemplate')
    return null
  }

  // render loading state
  if (props.podTemplate.get('loading')) {
    return <TextLineShimmer />
  }

  // render error loading state
  if (props.podTemplate.get('error')) {
    return <DynamicFormattedMessage {...messages.errrorLoadingData} />
  }

  const path = ['data', 'hasAttachedStorage', 'items']
  // pvc = persistent volume claim
  const pvcs = props.podTemplate.getIn(path, null)
  if (pvcs === null) {
    // eslint-disable-next-line no-console
    console.warn('K8sServiceDetails: podTemplate no data @ ' + path.join('/'))
    return <DynamicFormattedMessage {...messages.errrorLoadingData} />
  }

  return pvcs
    .toJS()
    .map((pvc, i) => (
      <K8sPVDetail key={i} pvc={pvc} onSelectedPVC={props.onSelectedPVC} />
    ))
}

export default function K8sPVDetails(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [paths, setPaths] = useState([])
  const [selectedPVC, setSelectedPVC] = useState({})
  const onSelectedPVC = pvc => {
    setSelectedPVC(pvc)
    setModalOpen(true)
    setPaths(fromJS([[props.nodeData.srn]]))
  }
  return (
    <Fragment>
      <br />
      <NodeViewDetailLabel>
        <DynamicFormattedMessage {...messages.persistentVolumes} />
      </NodeViewDetailLabel>
      <K8sPVDetailsBody {...{ ...props, onSelectedPVC }} />
      <ShowPathModal
        resourceId={selectedPVC.srn}
        pathEndItem={selectedPVC}
        classification={CLASSIFICATION}
        paths={paths}
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
      />
    </Fragment>
  )
}

K8sPVDetails.propTypes = {
  nodeData: PropTypes.object,
  podTemplate: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.error,
    data: ImmutablePropTypes.map,
  }),
}
