import { defineMessages } from 'react-intl'

export default defineMessages({
  affinitySectionHeader: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.affinitySectionHeader',
    defaultMessage: 'Affinity',
  },
  containersSectionHeader: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.containersSectionHeader',
    defaultMessage: 'Containers',
  },
  errrorLoadingData: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.errrorLoadingData',
    defaultMessage: 'There was an error loading data.',
  },
  errorPodTemplateData: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.errorPodTemplateData',
    defaultMessage: 'There was an error loading the pod template data.',
  },
  networkPolicies: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.networkPolicies',
    defaultMessage: 'Network Policies',
  },
  persistentVolumes: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.persistentVolumes',
    defaultMessage: 'Persistent Volumes',
  },
  selectedByServices: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.selectedByServices',
    defaultMessage: 'Selected By Services',
  },
  specSectionHeader: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.specSectionHeader',
    defaultMessage: 'Spec Details',
  },
  templateSpecSectionHeader: {
    id:
      'app.containers.K8sWorkloadNodeViewCardLayout.templateSpecSectionHeader',
    defaultMessage: 'Template Details',
  },
  viewControlHuman: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.viewControlHuman',
    defaultMessage: 'View as data layout',
  },
  viewControlJSON: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.viewControlJSON',
    defaultMessage: 'View as JSON',
  },
  viewControlYAML: {
    id: 'app.containers.K8sWorkloadNodeViewCardLayout.viewControlYAML',
    defaultMessage: 'View as YAML',
  },
})
