import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  GET_POD_TEMPLATE_DATA,
  GET_POD_TEMPLATE_DATA_ERROR,
  GET_POD_TEMPLATE_DATA_SUCCESS,
  GET_CLUSTER_INFRASTRUCTURE,
  GET_CLUSTER_INFRASTRUCTURE_ERROR,
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS,
} from './constants'

export const initialState = fromJS({
  clusterInfrastructure: {
    loading: false,
    error: false,
    data: null,
  },
  podTemplate: {
    loading: false,
    error: false,
    data: null,
  },
})

const k8sWorkloadNodeViewReducer = handleActions(
  {
    [GET_CLUSTER_INFRASTRUCTURE]: state =>
      state
        .setIn(['clusterInfrastructure', 'loading'], true)
        .setIn(['clusterInfrastructure', 'error'], false),
    [GET_CLUSTER_INFRASTRUCTURE_ERROR]: state =>
      state
        .setIn(['clusterInfrastructure', 'loading'], false)
        .setIn(['clusterInfrastructure', 'error'], true),
    [GET_CLUSTER_INFRASTRUCTURE_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['clusterInfrastructure', 'loading'], false)
        .setIn(['clusterInfrastructure', 'error'], false)
        .setIn(['clusterInfrastructure', 'data'], fromJS(payload.data)),
    [GET_POD_TEMPLATE_DATA]: state =>
      state
        .setIn(['podTemplate', 'loading'], true)
        .setIn(['podTemplate', 'error'], false),

    [GET_POD_TEMPLATE_DATA_ERROR]: state =>
      state
        .setIn(['podTemplate', 'loading'], false)
        .setIn(['podTemplate', 'error'], true),

    [GET_POD_TEMPLATE_DATA_SUCCESS]: (state, { payload }) =>
      state
        .setIn(['podTemplate', 'loading'], false)
        .setIn(['podTemplate', 'error'], false)
        .setIn(['podTemplate', 'data'], fromJS(payload.data)),
  },
  initialState
)

export default k8sWorkloadNodeViewReducer
