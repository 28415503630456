import { createAction } from 'redux-actions'
import {
  GET_POD_TEMPLATE_DATA,
  GET_POD_TEMPLATE_DATA_ERROR,
  GET_POD_TEMPLATE_DATA_SUCCESS,
  GET_CLUSTER_INFRASTRUCTURE,
  GET_CLUSTER_INFRASTRUCTURE_ERROR,
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS,
} from './constants'

export const getPodTemplateData = createAction(GET_POD_TEMPLATE_DATA)
export const getPodTemplateDataError = createAction(GET_POD_TEMPLATE_DATA_ERROR)
export const getPodTemplateDataSuccess = createAction(
  GET_POD_TEMPLATE_DATA_SUCCESS
)

export const getClusterInfrastructure = createAction(GET_CLUSTER_INFRASTRUCTURE)
export const getClusterInfrastructureSucces = createAction(
  GET_CLUSTER_INFRASTRUCTURE_SUCCESS
)
export const getClusterInfrastructureError = createAction(
  GET_CLUSTER_INFRASTRUCTURE_ERROR
)
