import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'

import messages from './messages'

const styles = {
  card: {
    height: '100%',
  },
  gridContainer: {
    height: '100vh',
  },
}

class K8sPodTemplateTab extends Component {
  getTableData = () => {
    return this.props.clusterInfrastructure
      .get('data')
      .toJS()
      .map(row => {
        return _.pick(row, [
          'name',
          'label',
          'type',
          'active',
          'createDate',
          'srn',
        ])
      })
  }
  renderBody = () => {
    if (this.props.clusterInfrastructure.get('loading')) {
      return <SquareLoadingAnimation />
    }

    if (this.props.clusterInfrastructure.get('error')) {
      return <DynamicFormattedMessage {...messages.errorPodTemplateData} />
    }

    return <DataTable data={this.getTableData()} />
  }

  render() {
    return (
      <div style={styles.gridContainer}>
        <BorderedCard style={styles.card}>{this.renderBody()}</BorderedCard>
      </div>
    )
  }
}

K8sPodTemplateTab.propTypes = {
  clusterInfrastructure: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.map,
  }),
}

export default K8sPodTemplateTab
