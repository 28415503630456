import React, { Fragment, useState } from 'react'
import _ from 'lodash'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'

import ShowPathModal from 'containers/AccessActivity/ShowPathModal'

import DyanmicFormattedMessage from 'components/DynamicFormattedMessage'
import { NodeViewDetailLabel } from 'components/NodeView'
import { TextLineShimmer } from 'components/ShimmerLoader'
import TextLink from 'components/TextLink'

import { metdataNestedObject } from 'utils/sonraiUtils'

import messages from './messages'

const CLASSIFICATION = 'Selected By Network Policy'

function K8sNetworkPolicy({ policy, onPolicySelected = () => {} }) {
  const { name, metadata: metadataRaw } = policy

  let details = ''
  if (metadataRaw) {
    const metadata = metdataNestedObject(metadataRaw)
    const policyTypes = _.get(metadata, ['policy', 'spec', 'policyTypes'], [])
    const labels = Object.values(
      _.get(metadata, ['policy', 'spec', 'podSelector', 'matchLabels'], {})
    )
    details = ` (${policyTypes.join('/')}: ${labels.join(',')})`
  }
  return (
    <div>
      <TextLink color="primary" onClick={() => onPolicySelected(policy)}>
        {name}
      </TextLink>
      {details}
    </div>
  )
}

K8sNetworkPolicy.propTypes = {
  policy: PropTypes.map,
  onPolicySelected: PropTypes.function,
}

function K8sNetworkPoliciesBody(props) {
  // cannot render anything w/ invalid props
  if (!props.podTemplate) {
    // eslint-disable-next-line no-console
    console.warn('K8sServiceDetails: expected to receive prop podTemplate')
    return null
  }

  // render loading state
  if (props.podTemplate.get('loading')) {
    return <TextLineShimmer />
  }

  // render error loading state
  if (props.podTemplate.get('error')) {
    return <DyanmicFormattedMessage {...messages.errrorLoadingData} />
  }

  const path = ['data', 'isSelectedByNetworkPolicy', 'items']
  const policies = props.podTemplate.getIn(path, null)

  if (policies === null) {
    // eslint-disable-next-line no-console
    console.warn(
      'K8sNodePolicyDetails: podTemplate no data @ ' + path.join('/')
    )
    return null
  }
  return policies.map((policy, i) => (
    <K8sNetworkPolicy
      key={i}
      policy={policy.toJS()}
      onPolicySelected={props.onPolicySelected}
    />
  ))
}

export default function K8sNodePolicyDetails(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [paths, setPaths] = useState([])
  const [selectedPolicy, setSelectedPolicy] = useState({})
  const onPolicySelected = policy => {
    setSelectedPolicy(policy)
    setModalOpen(true)
    setPaths(fromJS([[props.nodeData.srn]]))
  }
  return (
    <Fragment>
      <br />
      <NodeViewDetailLabel>
        <DyanmicFormattedMessage {...messages.networkPolicies} />
      </NodeViewDetailLabel>
      <K8sNetworkPoliciesBody {...{ ...props, onPolicySelected }} />
      <ShowPathModal
        resourceId={selectedPolicy.srn}
        pathEndItem={selectedPolicy}
        classification={CLASSIFICATION}
        paths={paths}
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
      />
    </Fragment>
  )
}

K8sNodePolicyDetails.propTypes = {
  nodeData: PropTypes.object,
  podTemplate: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.error,
    data: ImmutablePropTypes.map,
  }),
}
